import { useState } from "react";

// Interface that defines the shape of the state used for security order handling.
interface SecurityOrderState {
  showForm: boolean;
  dataClassificationConfirmIsChecked: boolean;
  securityPoliciesConfirmIsChecked: boolean;
  comment?: string;
  hasApproval: boolean;
  goNext: boolean;
  securitySummary: string;
  securityUrl: string;
  formIsValid: boolean;
}

/**
 * Custom hook to manage the state of a security order data.
 * It encapsulates all the fields in a single object and provides
 * a function to update specific fields in that object.
 *
 * @returns {object} An object containing the state `inputs` and the updater function `updateInputs`.
 *
 * `inputs`: The current state of the security order, including fields like `dataClassificationConfirmIsChecked`, `showForm`,
 * `securityPoliciesConfirmIsChecked`, and others.
 * `updateInputs`: A function to update a specific field in the `inputs` state. It takes two arguments:
 * `field`: The name of the field to update (must be a key from the `SecurityOrderState` interface).
 * `value`: The new value to assign to the specified field.
 */
export const useSecurityOrderState = () => {
  // Initialize the state for the security order with default values
  const [inputs, setInputs] = useState<SecurityOrderState>({
    showForm: false,
    dataClassificationConfirmIsChecked: false,
    securityPoliciesConfirmIsChecked: false,
    comment: undefined,
    hasApproval: true,
    goNext: true,
    securitySummary: "",
    securityUrl: "",
    formIsValid: false,
  });

  /**
   * Function to update a specific field in the `inputs` state object.
   * It merges the new value with the previous state, ensuring that only the targeted field is updated.
   *
   * @param {keyof SecurityOrderState} field - The name of the field to be updated.
   * @param {any} value - The new value to assign to the specified field.
   */
  const updateInputs = (field: string, value: any) => {
    setInputs((prev) => {
      if (prev[field as keyof typeof prev] !== value) {
        return {
          ...prev,
          [field]: value,
        };
      }
      return prev;
    });
  };

  return { inputs, updateInputs };
};
